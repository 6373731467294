import { GameStats } from '../../lib/localStorage';
import { Progress } from './Progress';

type Props = {
  gameStats?: GameStats;
  isGameWon?: boolean;
};

export const Histogram = ({ gameStats, isGameWon = false }: Props) => {
  const winDistribution = gameStats?.winDistribution;
  // console.log(winDistribution);
  const maxValue = winDistribution && Array.isArray(winDistribution) ? Math.max(...winDistribution) : 0;
// alert(maxValue);
  if (!winDistribution || !Array.isArray(winDistribution)) {
    return <div>No data available</div>;
  }
  return (
    <div className=" min_height flex pp-two text-sm dark:text-white backGroundColor">
      {winDistribution.map((value, i) => (
        
        <Progress
          key={i}
          index={i}
          size={90 * (value / maxValue)}
          label={String(value)}
          isGameWon = {isGameWon}
        />
      ))}
    </div>
  )
}
